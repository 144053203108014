export default function Timer() {
  let timer: Date | null = null
  let timeSpent = 0
  let state = 'stopped'
  return {
    start() {
      if (state === 'started') return
      timer = new Date()
      state = 'started'
    },
    stop() {
      if (state === 'stopped') return
      const start = timer
      const end = new Date()
      // @ts-ignore
      const diff = end.getTime() - start?.getTime()
      timeSpent += diff
      state = 'stopped'
    },
    getTimeSpent() {
      if (state === 'started') {
        const end = new Date()
        // @ts-ignore
        const diff = end.getTime() - timer?.getTime()
        return timeSpent + diff
      }
      return timeSpent
    },
    reset() {
      timeSpent = 0
      timer = new Date()
    },
  }
}
