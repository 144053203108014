//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import woothee from 'woothee'
import { PARENT_INTEGRATION } from '@clipr/shared-types'
import config from '~/cliprConfig'
import { sortStories } from '~/services/story'
import { getReferrerURL } from '~/utilities/functions'
import Timer from '~/utilities/Timer'
import { adsLoadingType, adsShortCodes, PRODUCT } from '~/utilities/constants'
import { PlayerMessage, MediaMessage } from '~/utilities/simid/constants'
import { usePixelsStore } from '~/store/pinia/pixels'

export default {
  name: 'Clipr',
  // Props useful when displayed as a widget
  props: {
    batchSize: {
      type: Number,
      default: 1,
    },
    maxBatches: {
      type: Number,
      default: 100,
    },
    customTitle: {
      type: Object,
      default: null,
    },
    cliprIsHub: {
      type: Boolean,
      default: false,
    },
    cliprIsMultiPlayer: {
      type: Boolean,
      default: false,
    },
    openedInHub: {
      type: Boolean,
      default: false,
    },
    cliprAllDevices: {
      type: Boolean,
      default: false,
    },
    cliprBlockStoryAutoplay: {
      type: Boolean,
      default: false,
    },
    cliprCloseUrl: {
      type: String,
      default: '',
    },
    cliprCloseMode: {
      type: String,
      default: '',
    },
    // Info from routing
    cliprIsList: {
      type: Boolean, // If true, slug is api key of a list of clips
      default: false,
    },
    cliprTestMode: {
      type: Boolean,
      default: false,
    },
    cliprOnlyNative: {
      type: Boolean, // For story editor : display only button and top navigation
      default: false,
    },
    cliprSimulator: {
      type: Boolean,
      default: false,
    },
    initialData: {
      type: Object,
      default: () => {},
    },
    initialStartShortCode: {
      type: String,
      default: '',
    },
    formatType: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      anyEventReceived: false,
      stories: [],
      isInIframe: false,
      closeButtonInfo: {
        closeMode: '',
        closeUrl: '',
      },
      touchEvents: {},
      blockStoryAutoplay: false,
      useCopyRangeMethod: false,
      isRunningInUncontrolledIframe: false,
      hasStoryLaunched: false,
      shouldResizeVideoWithJs: false,
      useDoubleIframedCTA: false,
      lastTouch: 0,
      startShortCode: '',
      eventsInterval: 2000,
      eventsTimeout: null,
      canInitAnalytics: false,
      hostUrl: getReferrerURL(),
      hostPixels: {
        impression: [],
        impressionVisible: [],
        cta: [],
      },
      overrideCtaUrl: '',
      macrosUrl: '',
      isDesktop: false,
      simidTimeUpdated: false,
    }
  },
  computed: {
    sentryContext() {
      if (typeof window === 'undefined') return {}
      const context = {
        hostUrl: this.hostUrl,
        product: this.$store.state.environment.product,
        url: window.location.href,
        currentShortCode: this.currentShortCode,
        insideIframe: window.self !== window.top,
      }

      if (this.$route.query.integration)
        context.integration = this.$route.query.integration

      if (this.$route.query.pi) {
        context.parentIntegration = this.getParentIntegration()
      }

      return context
    },
    carouselEndpoint() {
      return this.adsLoadType === adsLoadingType.AYL_SCRIPT ? 'l' : 'y'
    },
    adsLoadType() {
      return this.$store.state.ads.adsLoadType
    },
    currentShortCode() {
      return this.$store.getters['times/currentStoryCode']
    },
    carouselNotVisible() {
      if (this.cliprSimulator) return false

      return (
        !this.visibleInsidePage || !this.browserTabVisible || this.isMinimized
      )
    },
    pausedState() {
      // minimized happens only on carousel thumbs integration so when is closed by user, we force it to pause
      if (this.isMinimized || this.$store.state.environment.shouldVerifyAge)
        return true

      if (
        this.cliprIsMultiPlayer &&
        this.$store.state.ads.prerollAdDisplayed &&
        !this.isRunningInUncontrolledIframe
      ) {
        if (
          this.$store.state.media.current &&
          this.$store.state.media.current.isPrerollAd
        )
          return !this.$store.state.visibility
            .iframeVisibilityPercentageOverHalfAtLeastOnce

        return !this.browserTabVisible
      }

      // if SP or story, continue playing if first full view is not reached
      if (!this.cliprIsMultiPlayer && !this.fullStoryViewedAtLeastOnce)
        return false

      // in case of multiplayer we will keep playing until the visible event is received at least once
      if (
        this.cliprIsMultiPlayer &&
        !this.$store.state.visibility.iframeVisibleEventReceivedAtLeastOnce
      )
        return false

      return this.carouselNotVisible
    },
    isMinimized() {
      return this.$store.state.visibility.isMinimized
    },
    browserTabVisible() {
      return this.$store.state.visibility.browserTabVisible
    },
    visibleInsidePage() {
      return this.$store.state.visibility.visibleInsidePage
    },
    fullStoryViewedAtLeastOnce() {
      return this.$store.state.environment.fullStoryViewedAtLeastOnce
    },
    currentStoryIsPrebidAd() {
      const currentStory = this.$store.state.story.current
      return currentStory && currentStory.short_code === adsShortCodes.PREROLL
    },
    shouldShowStories() {
      return (
        !this.cliprIsSocialPost &&
        this.initialData.stories &&
        this.initialData.stories.length > 0
      )
    },
    cliprIsSocialPost() {
      if (
        !this.cliprIsMultiPlayer &&
        this.initialData.stories &&
        this.initialData.stories.length > 0 &&
        this.initialData.stories[0].content.fbAd &&
        this.initialData.stories[0].content.fbAd.panels &&
        this.initialData.stories[0].content.fbAd.panels.length > 0
      )
        return this.initialData.stories[0].type === 'FACEBOOK_AD'
      return false
    },
    shouldShowSocialPost() {
      return (
        !this.cliprIsMultiPlayer &&
        this.cliprIsSocialPost &&
        this.initialData.stories &&
        this.initialData.stories[0] &&
        this.initialData.stories[0].content &&
        this.initialData.stories[0].content.fbAd &&
        this.initialData.stories[0].content.fbAd.panels &&
        this.initialData.stories[0].content.fbAd.panels[0]
      )
    },
    enableVerticalScroll() {
      // Avoid vertical scroll being blocked when clip is embedded on website or when multiplayer
      return (
        this.cliprIsMultiPlayer ||
        ((this.isInIframe || this.isRunningInUncontrolledIframe) &&
          (!this.stories || this.stories.length <= 1))
      )
    },
    initialStories() {
      return this.initialData.stories || []
    },
    enableDynamicAds() {
      return this.initialData.ads_enabled || false
    },
    initialTags() {
      return this.initialData.integration || {}
    },
    startIndex() {
      if (this.stories.length === 0) {
        return -1
      }
      // Get the short code of the clip that needs to be shown first (if null, we'll launch the 1st clip of the list)
      const shortCode = this.startShortCode
      const index = this.stories.findIndex(
        (storyData) => storyData.short_code === shortCode,
      )
      return Math.max(0, index)
    },
    displayStories() {
      return this.blockStoryAutoplay || typeof this.stories === 'undefined'
        ? []
        : this.stories
    },
  },
  isStoryActive: true,
  watch: {
    // TODO: maybe create this watcher with $watch only for multiplayer in onmounted
    browserTabVisible: {
      immediate: true,
      handler(isVisible) {
        if (this.cliprIsMultiPlayer && typeof window !== 'undefined') {
          this.$store.dispatch('events/addPlayerEvent', {
            key: 'PLAYER_IS_VISIBLE',
            value: isVisible ? 1 : 0,
          })
        }
      },
    },
    sentryContext: {
      immediate: true,
      handler(context) {
        if (this.$config.sentryEnabled)
          this.$sentry.setContext('extraInfo', context)
      },
    },
    currentShortCode: {
      immediate: true,
      handler() {
        this.sendHostUrl()
      },
    },
    hostUrl: {
      immediate: true,
      handler() {
        this.sendHostUrl()
      },
    },
    pausedState: {
      handler(isPaused) {
        if (typeof window === 'undefined') return false
        if (isPaused) {
          if (this.cliprIsMultiPlayer) {
            if (this.sessionTimer) this.sessionTimer.stop()
            this.stopPlayerUpdateInterval()
            this.updatePlayerSessionDuration()
          }
          this.pauseUpdateSessionDuration()
        } else {
          if (this.cliprIsMultiPlayer) {
            if (this.sessionTimer) this.sessionTimer.start()
            this.startPlayerUpdateInterval()
          }
          this.resumeUpdateSessionDuration()
        }
      },
      immediate: true,
    },
    currentStoryIsPrebidAd() {
      this.$store.dispatch('storyRatio/windowResized')
    },
    hasStoryLaunched() {
      this.updateCanInitAnalytics()
    },
    blockStoryAutoplay() {
      this.updateCanInitAnalytics()
    },
  },
  beforeMount() {
    this.setProduct()
    if (
      this.cliprIsMultiPlayer &&
      this.enableDynamicAds &&
      this.carouselEndpoint === 'y'
    ) {
      this.$store.commit('environment/waitingToReceivePreroll', true)
      this.waitingForPrerollTimeout = window.setTimeout(() => {
        this.$store.commit('environment/waitingToReceivePreroll', false)
        this.$store
          .dispatch('events/addEventsFromQueue')
          .then(this.postEventsInQueue)
        this.waitingForPrerollTimeout = null
      }, 500)
    }
    // Init tcfApi stub
    const tcfapi = new this.$tcfApi()
    // Get tcfApi consent
    tcfapi.addTcfListener()
    // Is test mode ?
    this.$store.commit('events/setIsTestMode', this.cliprTestMode)

    // Analyze user agent to customize story behavior depending on device type
    const ghostVisitCode = this.analyzeUserAgent()

    // (case individual clip) Has story been published ?
    if (
      this.initialStories &&
      this.initialStories.length === 1 &&
      (this.initialStories[0].active === false ||
        parseInt(this.initialStories[0].active) === 0)
    ) {
      this.$options.isStoryActive = false
    }

    // If visitor is a bot we redirect to first website found
    if (ghostVisitCode === 3 && !this.cliprSimulator) {
      let shortCode = 'not-found'
      if (this.initialStories && this.initialStories.length > 0) {
        shortCode = this.initialStories[0].short_code
      }
      // Redirect to first external link available, but using a clipr API to count that ghost visit
      window.location =
        config.path.API_BASE_URL +
        config.routes.autoRedirect.open(shortCode, ghostVisitCode)
    } else if (
      !this.$options.isStoryActive &&
      !this.cliprTestMode &&
      !this.cliprSimulator &&
      !this.cliprIsList &&
      !this.cliprIsSocialPost
    ) {
      window.location = 'https://clipr.co'
    } else {
      // should not happen but there is a sentry issue about it
      // https://clipr-dg.sentry.io/issues/4202819142/?alert_rule_id=9006255&alert_type=issue&project=5998479&referrer=slack
      if (!this.closeButtonInfo) {
        this.closeButtonInfo = {
          closeMode: '',
          closeUrl: '',
        }
      }
      if (this.isRunningInUncontrolledIframe) {
        this.closeButtonInfo.closeMode = 'hidden'
        this.closeButtonInfo.closeUrl = ''
      } else {
        this.closeButtonInfo.closeMode =
          this.cliprCloseMode.length > 0
            ? this.cliprCloseMode
            : this.cliprGetQueryParam('closeMode')
        this.closeButtonInfo.closeUrl =
          this.cliprCloseUrl.length > 0
            ? this.cliprCloseUrl
            : this.cliprGetQueryParam('closeUrl')
      }

      // Make sure we won't have problems we session duration
      this.$store.commit('times/resetAllTimes')

      // Is embedded in an uncontrolled host iframe
      // WARNING : link simulator : isRunningInUncontrolledIframe always false
      this.isRunningInUncontrolledIframe =
        !['thumbs', 'delivery-script'].includes(
          this.$route.query.integration,
        ) &&
        (!this.cliprCloseMode || this.cliprCloseMode.length === 0) &&
        (!this.cliprSimulator || !this.cliprTestMode) &&
        window.self !== window.top

      if (!this.cliprIsSocialPost) {
        // enable story ratio if is in a carousel
        if (this.initialStories.length > 1 && !this.cliprIsMultiPlayer) {
          this.$store.commit('storyRatio/enabled', true)
        }
      }

      try {
        this.isInIframe = window !== window.parent
      } catch (e) {
        this.isInIframe = true
      }

      // Get ready to receive message from parent host through iframe
      this.$eventBus.$on('iframe/message-received', this.receiveMessage)

      // If we have some pixel url,
      // We are run under an iframe, and because of ad server redirection
      // We can't access host url
      // So we better send message to all window

      if (!this.cliprIsSocialPost) {
        this.triggerMessage({ key: 'APPLICATION_READY' }, '*')

        // we also emit the ready event upwards in case we need to know when the app is ready
        this.$emit('applicationReady')
      }
    }
  },
  created() {
    // thumbs carousel will load the stories before a clip is clicked
    // we set this here to prevent stories from autoplaying and sending events
    if (this.$route.query.integration === 'thumbs')
      this.$store.commit('visibility/isMinimized', true)
  },
  mounted() {
    const pixelsStore = usePixelsStore(this.$pinia)

    this.$log.info('Welcome to Clipr! Current version: ' + config.appVersion)

    if (typeof window !== 'undefined') {
      setTimeout(() => {
        this.$store.dispatch('events/addEvent', {
          key: 'PERFORMANCE_CLIPR_MOUNTED',
          short_code: this.currentShortCode,
          value: performance.now(),
        })
      }, 100)

      this.resumeStoriesComponent()

      if (this.cliprIsMultiPlayer) {
        this.sessionTimer = new Timer()
        if (!this.carouselNotVisible) this.sessionTimer.start()

        this.$store.dispatch('events/addPlayerEvent', {
          key: 'PLAYER_IMPRESSION',
        })
        this.startPlayerUpdateInterval()
      }
    }
    if (
      !this.$options.isStoryActive &&
      !this.cliprTestMode &&
      !this.cliprSimulator &&
      !this.cliprIsList &&
      !this.cliprIsSocialPost
    ) {
      window.location = 'https://clipr.co'
    } else {
      this.stories = this.initialStories

      this.blockStoryAutoplay = this.cliprBlockStoryAutoplay
      if (this.blockStoryAutoplay) {
        this.pauseUpdateSessionDuration()
      } else if (this.initialStartShortCode.length > 0) {
        this.startShortCode = this.initialStartShortCode
      } else if (this.stories && this.stories.length > 0) {
        this.startShortCode = this.stories[0].short_code
      }

      // Watermark params
      this.$store.commit(
        'watermark/setWatermark',
        this.initialData.watermark || false,
      )

      if (
        this.touchEvents &&
        this.touchEvents.touchStartEventName &&
        !this.cliprIsSocialPost
      ) {
        document.addEventListener(
          this.touchEvents.touchStartEventName,
          this.preventZoom,
          this.preventZoom,
          { passive: false },
        )
        document.addEventListener(
          this.touchEvents.touchSliderEvents.move,
          this.preventMotion,
          { passive: false },
        )
        if (!this.enableVerticalScroll) {
          // Carousel is enable, block vertical scroll even if embed in iframe
          document.addEventListener(
            this.touchEvents.touchSliderEvents.move,
            this.resetPreventZoom,
            { passive: false },
          )
        }
      }
    }

    // we have to register play/pause for dapi protocol
    // link is loaded before showing the AD, so we should play/pause base on AD visibility
    if (this.$mobileAds.isActive()) {
      this.$mobileAds.registerResumeMethod(this.resumeStoriesComponent)
      this.$mobileAds.registerPauseMethod(this.pauseStoriesComponent)
    }

    /**
     * Simid Initializing, we use a flag like ?simid=1 or anything that define it to enable the simid plugin
     */
    if (typeof window !== 'undefined' && this.cliprGetQueryParam('simid')) {
      // Simid protocol can't be passed on this scope because it need to keep the same
      // origin, using vue this. will move it to the vue scope and error will be displayed.
      // THE $simidProtocol plugin will create window.simidProtocol so it can be used anywhere else
      const simid = new this.$simidProtocol()

      const self = this // Scope trick

      // Intercept Player Init to get the URI inside the VAST and override our CTAs with the client
      // setted URI while keeping count of via our event-router. This operation enable counting from
      // DSP, Ad Verifications, ... and most important OUR counting.
      simid.simidProtocol.addListener(PlayerMessage.INIT, (data) => {
        // This will override every URI even for multi-panels with different links.
        self.overrideCtaUrl = data.args.creativeData.clickThruUri
        this.$log.simid(PlayerMessage.INIT, self.overrideCtaUrl)
      })

      // Intercept Player Play to resume our social post content after player's pause to match timing
      // between media file and story
      simid.simidProtocol.addListener(MediaMessage.PLAY, () => {
        this.$log.simid(MediaMessage.PLAY)

        const creative = this.$refs.socialPost || this.$refs.stories
        this.$refs.socialPost
          ? creative.resumeCurrentItem()
          : creative.resumeStory()
      })

      // Intercept Player Pause to stop our social post content after player's play to match timing
      // between media file and story
      simid.simidProtocol.addListener(MediaMessage.PAUSE, () => {
        this.$log.simid(MediaMessage.PAUSE)

        const creative = this.$refs.socialPost || this.$refs.stories
        this.$refs.socialPost
          ? creative.pauseCurrentItem()
          : creative.pauseStory()
      })

      // Intercept Player Ended to send complete event to our analytics
      simid.simidProtocol.addListener(MediaMessage.ENDED, () => {
        this.$log.simid(MediaMessage.ENDED)

        const creative = this.$refs.socialPost || this.$refs.stories
        if (this.$refs.socialPost) {
          this.$log.simid('Sending video complete event for social post')
          creative.sendVideoCompletionRate({
            rate: 1,
            force: true,
          })
        } else {
          this.$log.simid('Sending video complete event for stories')
          this.$eventBus.$emit('activeStory/addCompletionEvent', 1)
        }
      })

      simid.simidProtocol.addListener(MediaMessage.TIME_UPDATE, (payload) => {
        this.$log.simid(MediaMessage.TIME_UPDATE)

        const time = payload.args.currentTime

        if (time && !this.simidTimeUpdated) {
          this.simidTimeUpdated = true

          this.$log.simid(
            `Received ${MediaMessage.TIME_UPDATE} event, synchronizing video.`,
          )

          this.updateCurrentVideoTime(time)
        }
      })
    }

    /**
     * Disable pixel submit for VAST or VPAID integration
     * Temporary turned off
     */
    // if (this.cliprGetQueryParam('simid') || this.cliprGetQueryParam('vpaid')) {
    //   pixelsStore.setDisabled(true)
    // }
  },
  destroyed() {
    // Stop POST events
    this.startShortCode = ''
    this.pauseStoriesComponent()

    // Remove all events listeners
    if (this.touchEvents && this.touchEvents.touchStartEventName) {
      document.removeEventListener(
        this.touchEvents.touchStartEventName,
        this.preventZoom,
        { passive: false },
      )
      document.removeEventListener(
        this.touchEvents.touchSliderEvents.move,
        this.preventMotion,
        { passive: false },
      )
      document.removeEventListener(
        this.touchEvents.touchSliderEvents.move,
        this.resetPreventZoom,
        { passive: false },
      )
    }
  },
  methods: {
    getParentIntegration() {
      if (this._parentIntegrationId) return this._parentIntegrationId

      const parentIntegrationId = this.$route.query.pi
      const idToStringMap = Object.keys(PARENT_INTEGRATION).reduce(
        (acc, key) => {
          acc[PARENT_INTEGRATION[key]] = key
          return acc
        },
        {},
      )
      this._parentIntegrationId = idToStringMap[parentIntegrationId]
      return this._parentIntegrationId
    },
    setProduct() {
      let product = PRODUCT.NONE
      if (this.cliprIsSocialPost) product = PRODUCT.SOCIAL_POST
      else if (this.cliprIsMultiPlayer) {
        if (this.$store.state.environment.isSmallMultiPlayer)
          product = PRODUCT.SMALL_PLAYER
        else product = PRODUCT.PLAYER
      } else if (this.formatType) {
        product = PRODUCT.RETAIL
      } else if (this.$route.query.integration === 'thumbs')
        product = PRODUCT.CAROUSEL
      else if (!this.cliprIsList) product = PRODUCT.INDIVIDUAL_STORY

      this.$store.commit('environment/product', product)
    },
    sendHostUrl() {
      if (!this.hostUrl || !this.currentShortCode) return

      this.$store.dispatch('events/addEvent', {
        key: 'PAGE_URL',
        short_code: this.currentShortCode,
        value: this.hostUrl,
      })
    },
    startPlayerUpdateInterval() {
      window.clearTimeout(this.playerUpdateInterval)
      this.updatePlayerSessionDuration()
      this.playerUpdateInterval = window.setInterval(
        this.updatePlayerSessionDuration,
        3000,
      )
    },
    stopPlayerUpdateInterval() {
      window.clearTimeout(this.playerUpdateInterval)
    },
    updatePlayerSessionDuration() {
      if (!this.sessionTimer) return
      this.$store.dispatch('events/addPlayerEvent', {
        key: 'PLAYER_SESSION_DURATION',
        value: this.sessionTimer.getTimeSpent(),
      })
    },
    loadedSocialPost() {
      const shortCode = this.initialData.stories[0].short_code
      this.$store.dispatch('events/addEvent', {
        key: 'DOMLoad',
        value: -1,
        short_code: shortCode,
        init: {
          b: this.initialData.b.i,
          o: this.initialData.b.o,
          t: 'FACEBOOK_AD',
        },
      })
      this.$store.dispatch('times/updateTimes', shortCode)
      this.hasStoryLaunched = true
    },
    editStartShortCode(newShortCode) {
      this.startShortCode = newShortCode
    },
    /**
     * @returns {Integer}
     */
    analyzeUserAgent() {
      // Parse navigator user agent
      const userAgentInfo = woothee.parse(navigator.userAgent)

      const isSafari = userAgentInfo.name.toLowerCase() === 'safari'
      const isChrome = userAgentInfo.name.toLowerCase() === 'chrome'
      const isDesktop = userAgentInfo.category.toLowerCase() === 'pc'
      this.isDesktop = isDesktop

      const isIOs = ['iphone', 'ipod', 'ios'].includes(
        userAgentInfo.os.toLowerCase(),
      )
      const isAndroid = userAgentInfo.os.toLowerCase() === 'android'
      const isMobile = ['mobilephone', 'smartphone'].includes(
        userAgentInfo.category.toLowerCase(),
      )
      const isBot = ['crawler', 'unknown'].includes(
        userAgentInfo.category.toLowerCase(),
      )

      // Behavior
      this.shouldResizeVideoWithJs = isMobile && isChrome && isAndroid
      this.useDoubleIframedCTA = isIOs && isChrome // Chrome will may reload the page if scrolling from top of the screen
      this.useCopyRangeMethod = isSafari && isMobile
      if (this.$refs.stories) {
        this.$refs.stories.setAutoplayResolveMethod('btn-play')
      }

      // Touch events to use depending on devices
      this.touchEvents = {
        touchStartEventName: isSafari || isDesktop ? 'click' : 'touchstart',
        touchSliderEvents: {
          start: isMobile ? 'touchstart' : 'mousedown',
          move: isMobile ? 'touchmove' : 'mousemove',
          end: isMobile ? 'touchend' : 'mouseup',
        },
        resetTouchEventName: !isIOs || !isMobile ? 'click' : 'touchstart',
      }

      if (isBot) {
        // This value is legacy, it's the bot value
        // We used to redirect non-mobile device too with code 2 and 1
        return 3
      }

      // Now we wont redirect any other device, so we return 0 for all device except for bots
      return 0
    },
    cliprGetQueryParam(name) {
      return this.$route.query[name]
    },
    preventZoom(e) {
      const t2 = e.timeStamp
      const t1 = this.lastTouch || t2
      const dt = t2 - t1
      const fingers = typeof e.touches !== 'undefined' ? e.touches.length : 1
      this.lastTouch = t2

      // If time diff above 500, browser won't consider it as zoom tap
      if (!dt || dt >= 500 || fingers > 1) {
        return
      }

      this.resetPreventZoom()

      if (
        typeof e.target.getAttribute === 'function' &&
        e.target.getAttribute('type') !== 'checkbox' &&
        !['a', 'button'].includes(e.target.tagName.toLowerCase())
      ) {
        // We transform the "double click" into two single click
        // If target is a button only
        /**
         * ! WARNING !
         * if target is the story, this will simulate a click "go to previous media" !
         */
        // e.target.click()
        e.preventDefault()
      }
    },
    resetPreventZoom() {
      this.lastTouch = 0
    },
    pauseUpdateSessionDuration() {
      // Update session durations
      this.$store.dispatch('times/updateTimes').then(() => {
        // Once done, stop update until story resumed
        this.$store.commit('times/editShouldUpdateSessionDuration', false)
        // Also stop sending events to server
        clearTimeout(this.eventsTimeout)
      })
    },
    resumeUpdateSessionDuration() {
      this.$store.commit('times/editShouldUpdateSessionDuration', true)
      // Start to send events again to server
      clearTimeout(this.eventsTimeout)
      this.postEventsInQueue()
    },
    preventMotion(event) {
      const target = event.target || event.srcElement
      if (!target || !target.classList) return
      const isSwipeable = target.classList.contains('swipeable')
      // Block movement if two+ fingers on screen
      if (typeof event !== 'undefined' && event !== null && !isSwipeable) {
        const fingers =
          typeof event.touches !== 'undefined' ? event.touches.length : 1
        if (fingers > 1 || !this.enableVerticalScroll) {
          // !!! WARNING !!! So block vertical scroll won't work if one clip only, embedded on host
          event.preventDefault()
          event.stopPropagation()
        }
      }
    },
    // TODO: remove type and rename it to pixelType or include type inside value alongside the path of the pixel
    receiveMessage({ key, value = null, firstTag = null, type = null } = {}) {
      // When host of stories send  message through the iframe
      this.anyEventReceived = true

      switch (key) {
        case 'APPLICATION_READY':
          // GO_AND_PLAY_CLIP is not triggered in hub, but this one is
          if (this.openedInHub || this.cliprIsHub)
            this.$store.commit('visibility/isMinimized', false)
          break
        case 'GO_AND_PLAY_CLIP': {
          if (value && Object.keys(value).length > 0) {
            // this event is sent by clip-embedded.js when the fullscreen carousel is launched
            this.$store.commit('visibility/isMinimized', false)
          } else {
            // clip-embedded.js will fire a go and play event with empty value after minimized
            this.$store.commit('visibility/isMinimized', true)
          }
          const query = new URLSearchParams(window.location.search)
          if (parseInt(query.get('track_latency')) === 1) {
            // window.backupTestBatchId = '4_march_ionut_old'
            window.getNextMediaId = () => {
              const targetMediaIndex = this.$refs.stories.activeMediaIndex + 1
              const storyIndex = this.$refs.stories.activeStoryIndex
              const nextMedia =
                this.stories[storyIndex].content.story[targetMediaIndex]
              return nextMedia.id
            }
            window.getPreviousMediaId = () => {
              const targetMediaIndex = this.$refs.stories.activeMediaIndex - 1
              const storyIndex = this.$refs.stories.activeStoryIndex
              const prevMedia =
                this.stories[storyIndex].content.story[targetMediaIndex]
              return prevMedia.id
            }
            window.getNextStoryMediaId = () => {
              const storyIndex = this.$refs.stories.activeStoryIndex
              const nextMedia = this.stories[storyIndex + 1].content.story[0]
              return nextMedia.id
            }
            window.getPreviousStoryMediaId = () => {
              const storyIndex = this.$refs.stories.activeStoryIndex
              const mediaIndex =
                this.$refs.stories.storiesLatestMediaIndex[storyIndex - 1] || 0
              const prevMedia =
                this.$refs.stories.stories[storyIndex - 1].content.story[
                  mediaIndex
                ]
              return prevMedia.id
            }

            this.$measure.$on('GO_TO_NEXT_VIDEO', () =>
              this.$refs.stories.goToNextMedia(),
            )
            this.$measure.$on('GO_TO_NEXT_STORY', () =>
              this.$refs.stories.changeStory(
                this.$refs.stories.activeStoryIndex + 1,
              ),
            )
            this.$measure.$on('GO_TO_PREVIOUS_VIDEO', () =>
              this.$refs.stories.goToPreviousMedia(),
            )
            this.$measure.$on('GO_TO_PREVIOUS_STORY', () =>
              this.$refs.stories.changeStory(
                this.$refs.stories.activeStoryIndex - 1,
              ),
            )

            const targetStoryIndex = this.stories.findIndex(
              (storyData) => storyData.short_code === value.shortCode,
            )
            const targetStory = this.stories[targetStoryIndex]

            this.$measure.init({
              batchName: query.get('latency_batch') || window.backupTestBatchId,
              startingMediaId: targetStory.content.story[0].id,
            })
          }

          const newCode = value ? value.shortCode : null
          const newStoriesOrder = value ? value.clipsOrder : null

          if (newCode && newCode.length > 0) {
            // Re-order stories if necessary
            const newOrderInfo = sortStories(this.stories, newStoriesOrder)
            if (newOrderInfo.changed) {
              this.stories = newOrderInfo.stories
            }

            this.$nextTick(() => {
              // Switch from story
              // If we click on same clip button again, startShortCode won't change
              // Thus carousel won't be re-rendered
              if (newCode === this.startShortCode) {
                // That's why we nullify the code first
                this.startShortCode = ''
                this.$nextTick(() => {
                  this.startShortCode = newCode
                })
              } else {
                this.startShortCode = newCode
              }

              // Count time spent again on that short code
              this.$store.commit('times/editShortCode', this.startShortCode)

              // Allow Stories component to download and play media
              this.blockStoryAutoplay = false
              if (!this.cliprIsSocialPost) {
                this.$nextTick(() => {
                  if (this.carouselNotVisible) {
                    window.setTimeout(() => this.resumeStoriesComponent(), 1000)
                  } else this.resumeStoriesComponent()
                })
              }
            })
          } else {
            // Do nothing except resetting startShortCode
            // This way we won't have re-render refresh problem if same clip button triggered
            this.startShortCode = ''
            this.$store.commit('times/editShortCode', '')

            // Stop updating session duration
            this.pauseUpdateSessionDuration()
          }
          break
        }
        case 'RESUME_CURRENT_CLIP':
          this.resumeStoriesComponent()

          break

        case 'PAUSE_CURRENT_CLIP':
          // this event is sent by clip-embedded.js when the fullscreen carousel is closed
          // at this stage, the iframe is not destroyed, but just minimized
          this.$store.commit('visibility/isMinimized', true)

          break

        case 'IFRAME_VISIBILITY_OVER_50':
          if (this.cliprIsMultiPlayer) {
            this.$store.dispatch('events/addPlayerEvent', {
              key: 'PLAYER_IS_VISIBLE',
              value: 1,
            })
          }
          break

        case 'IFRAME_VISIBILITY_UNDER_50':
          if (this.cliprIsMultiPlayer) {
            this.$store.dispatch('events/addPlayerEvent', {
              key: 'PLAYER_IS_VISIBLE',
              value: 0,
            })
          }
          break

        case 'PAGE_URL':
          if (value) {
            this.hostUrl = value
          }
          break

        case 'PXL_URL_IMPRESSION':
          this.addPixelUrlsToQueue('PXL_URL_IMPRESSION', { value, type })
          break

        case 'PXL_URL_IMPRESSION_VISIBLE':
          this.addPixelUrlsToQueue('PXL_URL_IMPRESSION_VISIBLE', {
            value,
            type,
          })
          break

        case 'PXL_URL_CTA':
          this.addPixelUrlsToQueue('PXL_URL_CTA', { value, type })
          break

        case 'CLIPR_DYN_ADS_RESPONSE':
          this.$eventBus.$emit('ads/external-response', value)
          break

        // Override the CTA redirection URL, it can contain macros from DSP or client specific click counter
        case 'OVERRIDE_CTA_URL':
          if (value && value.length > 0) {
            this.overrideCtaUrl = value
          }
          break

        case 'macros':
          if (value && value.length > 0) {
            this.macrosUrl = value
            this.$store.commit('environment/macrosUrl', value)
          }
          break

        case 'AYL_AD_PLACEMENT':
          this.$store.dispatch('ads/updateAYLAdPlacement', value)
          this.$log.ads(`RECEIVED ayl placement`, {
            key,
            value,
            firstTag,
            type,
          })
          this.$store.dispatch('events/addEvent', {
            key: 'AYL_AD_PLACEMENT',
            short_code: this.currentShortCode,
            value: { key, value, type },
          })
          if (firstTag) {
            this.$eventBus.$emit('ads/insertFirstTag', firstTag)

            if (this.waitingForPrerollTimeout) {
              window.clearTimeout(this.waitingForPrerollTimeout)
              this.$nextTick(() => {
                this.$store.commit('environment/waitingToReceivePreroll', false)
                this.$store.commit('events/resetQueue')
              })
            }
          }
          break

        case 'UPDATE_VIDEO_TIME':
          if (!isNaN(value)) {
            this.updateCurrentVideoTime(value)
          }
          break

        case 'VPAID_AD_VIDEO_COMPLETE':
          if (this.$refs.socialPost) {
            this.$log.vpaid('Sending video complete event for social post')
            this.$refs.socialPost.sendVideoCompletionRate({
              rate: 1,
              force: true,
            })
          } else {
            this.$log.vpaid('Sending video complete event for stories')
            this.$eventBus.$emit('activeStory/addCompletionEvent', 1)
          }
          break

        case 'VPAID_AD_PAUSED':
          this.$refs.socialPost
            ? this.$refs.socialPost.pauseCurrentItem()
            : this.$refs.stories.pauseStory()
          break

        case 'VPAID_AD_PLAYING':
          this.$refs.socialPost
            ? this.$refs.socialPost.resumeCurrentItem()
            : this.$refs.stories.resumeStory()
          break
      }
    },
    addPixelUrlsToQueue(pixel, payload) {
      const type = payload.type || 'image' // Could be javascript or image
      let links = payload.value
      if (type === 'image' && typeof payload.value === 'string') {
        links = payload.value.replace(/\s/g, '').split(',')
      }
      for (let i = 0; i < links.length; i++) {
        const link = links[i]
        switch (pixel) {
          case 'PXL_URL_IMPRESSION':
            this.hostPixels.impression.push({
              src: link,
              type,
            })
            break
          case 'PXL_URL_IMPRESSION_VISIBLE':
            this.hostPixels.impressionVisible.push({
              src: link,
              type,
            })
            break
          case 'PXL_URL_CTA':
            this.hostPixels.cta.push({
              src: link,
              type,
            })
            break
          default:
            break
        }
      }
    },
    postEventsInQueue() {
      // Update times spent on each story
      this.$store.dispatch('times/updateTimes').then(() => {
        // Send analytics events to API
        if (/^ATGN/.test(this.startShortCode)) {
          this.$log.events('clip is ATGN, not sending events')
        } else if (this.eventsInterval !== null)
          this.$store // if eventsInterval is null, we stop sending any events
            .dispatch('events/postEvents', {
              times: this.$store.getters['times/storiesTimes'],
            })
            .then(() => {
              // Plan another events sending once previous operation is done
              // Except for simulator, we don't want to monitor events

              // increase interval over time, in function of session duration
              const times = Object.values(
                this.$store.getters['times/storiesTimes'],
              )
              const minDuration = times.length === 0 ? 2000 : Math.min(...times)
              // Power function to increase interval:
              const fact = 0.55
              this.eventsInterval =
                Math.floor(Math.pow(minDuration / 1000, fact) + 1) * 1000
              // If session is too long (more than 30min), there is no need to plan to send anymore events:
              if (minDuration > 30 * 60 * 1000) this.eventsInterval = null

              if (
                !this.cliprSimulator &&
                this.startShortCode.length > 0 &&
                this.eventsInterval > 0
              ) {
                clearTimeout(this.eventsTimeout)
                this.eventsTimeout = setTimeout(() => {
                  if (
                    !this.$store.state.times.shouldUpdateSessionDuration ||
                    this.pausedState
                  ) {
                    return this.pauseUpdateSessionDuration()
                  }
                  this.postEventsInQueue()
                }, this.eventsInterval)
              }
            })
            .catch(() => {
              // If there is an error, we retry in 5 seconds
              this.eventsTimeout = setTimeout(() => {
                this.postEventsInQueue()
              }, 5000)
            })
      })
    },
    triggerMessage(data) {
      // formatType means Retail. Should be replaced with something else
      if (
        (this.cliprIsSocialPost || this.formatType) &&
        data &&
        data.key === 'USER_VIEWED_FULL_STORY'
      ) {
        this.$store.commit('environment/fullStoryViewedAtLeastOnce', true)
      }

      if (
        data &&
        data.key &&
        ['LAST_CLIP_STORY_ENDED', 'USER_PRESSED_CLOSE_BUTTON'].includes(
          data.key,
        ) &&
        this.$route.query.integration === 'thumbs'
      ) {
        // story is closing, we should mark the carousel as minimized
        this.$store.commit('visibility/isMinimized', true)
      }
      // Send message to host of the VueJS (case we are running on a
      // iframe)
      if (this.isInIframe && data.key && process.client) {
        this.$log.iframeEvent(
          `SENDING event ${data.key}${
            window.name ? ` on iframe with name ${window.name}` : ''
          } with payload`,
          data,
        )
        window.parent.postMessage(
          {
            ...data,
            name: window.name,
          },
          /* host */ '*',
        )
      }
    },
    pauseStoriesComponent() {
      if (this.$refs.socialPost) {
        this.$refs.socialPost.pauseCurrentItem()
      }
      // Let's pause the story
      if (this.$refs.stories) {
        this.$refs.stories.pauseStory()
      }
    },
    resumeStoriesComponent() {
      if (this.$refs.socialPost) {
        this.$refs.socialPost.resumeCurrentItem()
        return false
      }
      if (!this.blockStoryAutoplay) {
        // User is back on tab
        // Resume story
        if (this.$refs.stories) {
          this.$refs.stories.resumeStory()
        }
        // Restart updating session duration
      }
    },
    updateCurrentVideoTime(value) {
      this.$log.video('Received video time update:', value)
      if (this.$refs.socialPost) {
        this.$refs.socialPost.updateCurrentVideoTime(value)
      } else if (this.$refs.stories) {
        this.$eventBus.$emit('activeStory/updateCurrentVideoTime', value)
      }
    },
    updateCanInitAnalytics() {
      this.canInitAnalytics =
        this.canInitAnalytics || // Once true, should never be passed to false again
        (Object.keys(this.initialTags).length > 0 &&
          (this.hasStoryLaunched || this.blockStoryAutoplay)) // Can init tags if story empty embedded in hub or headers (but only clipr tags)
    },
  },
}
