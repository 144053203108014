export function sortStories(stories, orderParam) {
  // Re-order stories if necessary
  const order = orderParam

  if (!order || order.length === 0 || !stories || stories.length === 0) {
    // No need to sort stories
    return {
      stories,
      changed: false,
    }
  }

  // Convert to array
  const orderSplit = order.split(',')

  // For "changed" field in returned value
  const oldOrderedShortCodes = stories.map((story) => story.short_code)

  // Order is a list of clip short codes
  // stories list should respect that order
  const orderedStories = []
  const addedStories = []

  // Respect order
  for (const i in orderSplit) {
    const shortCode = orderSplit[i]
    for (const j in stories) {
      const story = stories[j]
      if (story.short_code && story.short_code === shortCode) {
        orderedStories.push(story)
        addedStories.push(shortCode)
        break
      }
    }
  }

  // Add stories that were not in order ist
  for (const i in stories) {
    const story = stories[i]
    if (story.short_code && !addedStories.includes(story.short_code)) {
      orderedStories.push(story)
    }
  }

  return {
    stories: orderedStories,
    changed: oldOrderedShortCodes.join(',') !== order,
  }
}
